import { useNavigation, useRoute } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { ComponentProps, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { AddMembersSection } from '@components/Common/AddMembersSection';
import ProjectCard from '@components/ProjectCard/ProjectCard';
import Button from '@components/shared/Button/Button';
import ListNavigationItem from '@components/shared/ListNavigationItem/ListNavigationItem';
import { FormValues } from '@components/Tasks/TaskFormStack';
import { useGetProjectLazyQuery } from '@graphql/generated';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
import { localDateFromUTCDate } from '@utils/formatters/date';

import Box from '../Box/Box';
import { Text } from '../Restyle/index';
import Icon from '../shared/Icon/Icon';
import TextField from '../shared/TextField/TextField';

type TaskCreateFormModalProps = {
  onProjectPress: () => void;
  onDatePress: () => void;
  parentProject?: boolean;
  selectedList?: [];
  taskname?: string;
} & ComponentProps<typeof AddMembersSection>;

export const TaskCreateModalForm: React.FC<TaskCreateFormModalProps> = ({
  onProjectPress,
  onDatePress,
  parentProject,
  taskname,
  ...addMembersSectionProps
}) => {
  const { t } = useTranslation();

  const { values, errors, touched, setTouched, setFieldValue } =
    useFormikContext<FormValues>();
  const { projectId, dueDate, name } = values;

  const route =
    useRoute<TasksStackScreenProps<'task-copy-create-modal'>['route']>();

  const [getProjectLazy, { data }] = useGetProjectLazyQuery({
    variables: {
      id: projectId,
    },
  });
  const { getProject: selectedProject } = data || {
    selectedProject: undefined,
  };

  useEffect(() => {
    if (projectId) {
      getProjectLazy({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId]);

  const getTaskname = () => {
    if (taskname === undefined) return null;
    return taskname;
  };
  const navigation = useNavigation();

  return (
    <Box flex={1}>
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <Box
          paddingBottom='s'
          paddingHorizontal='s'
          maxWidth={Platform.OS === 'web' ? 560 : undefined}>
          <Box flex={1} marginHorizontal='xxs' style={{ paddingBottom: 40 }}>
            {route.name !== 'task-copy-create-modal' && (
              <Box
                borderBottomColor='grey02'
                borderBottomWidth={1}
                marginTop='l'
                paddingTop='xxs'
                paddingBottom='xxs'>
                <ListNavigationItem
                  isLarge
                  title='Copy from existing task'
                  iconName='Copy'
                  onPress={() => navigation.navigate('copy-from-task')}
                />
              </Box>
            )}

            <Field
              component={TextField}
              label='Name'
              name='name'
              accessibilityLabel='Name'
              isRequired
              placeholder={t('models:tasks.placeholders.nameShortModal')}
              borderless={true}
              isLargeText={true}
              autoFocus
              marginBottom='xs'
              showCharsRemaining={name?.length > 0}
              textInputProps={{ maxLength: 25 }}
              defaultValue={getTaskname()}
            />
            <Field
              component={TextField}
              label='Description'
              name='description'
              accessibilityLabel='Description'
              placeholder={t('models:tasks.placeholders.descriptionShortModal')}
              borderless={true}
              showCharsRemaining
              textInputProps={{ maxLength: 500 }}
            />

            <Box
              marginBottom='xs'
              flexDirection='row'
              justifyContent='space-between'
              alignItems='center'>
              <Text variant='labelSmall'>
                {t('models:tasks.create.project.title')}
              </Text>
              <Text variant='metadataSecondary' color='textSecondary'>
                {t('shared:required')}
              </Text>
            </Box>
            {selectedProject && projectId ? (
              <Box
                paddingBottom='xs'
                borderBottomColor='grey02'
                borderBottomWidth={1}>
                <ProjectCard
                  project={{
                    authorId: selectedProject.authorId,
                    initials: selectedProject.initials,
                    id: selectedProject.id,
                    color: selectedProject.color,
                    createdAt: selectedProject.createdAt,
                    creator: selectedProject.creator,
                    name: selectedProject.name,
                    address: selectedProject.address,
                    team: selectedProject.team,
                  }}
                  onClick={onProjectPress}
                  onDelete={
                    !parentProject
                      ? () => {
                          setTouched({ ...touched, projectId: true });
                          setFieldValue('projectId', undefined);
                        }
                      : undefined
                  }
                  disabled={parentProject}
                  expanded={false}
                />
              </Box>
            ) : (
              <>
                <Button
                  accessibilityLabel={t(
                    'models:tasks.create.project.addToProject'
                  )}
                  variant='edit'
                  fullWidth
                  onPress={onProjectPress}
                  isSmall>
                  {t('models:tasks.create.project.addToProject')}
                </Button>
                {errors.projectId && touched.projectId && (
                  <Box flexDirection='row' alignItems='center' marginTop='s'>
                    <Icon
                      name='AlertTriangle'
                      color='alertRed'
                      variant='s'
                      marginRight='xxs'
                    />
                    <Text variant='error'>{errors.projectId}</Text>
                  </Box>
                )}
              </>
            )}

            <Text
              variant='labelSmall'
              marginTop='m'
              accessibilityLabel={t('models:tasks.create.dates.dueDate')}>
              {t('models:tasks.create.dates.dueDate')}
            </Text>
            <Box marginBottom='xxs' marginTop='xs'>
              <Box flex={1}>
                <Button
                  accessibilityLabel={
                    dueDate
                      ? t('format:monthAndDay', {
                          val: localDateFromUTCDate(dueDate),
                        })
                      : t('models:tasks.create.dates.dueDate')
                  }
                  variant={dueDate ? 'editSelected' : 'edit'}
                  onPress={onDatePress}
                  prefixMarginRight='xs'
                  prefix={
                    <Icon
                      name='Calendar'
                      color={dueDate ? 'textPrimary' : 'grey04'}
                    />
                  }
                  isSmall>
                  {dueDate
                    ? t('format:monthAndDay', {
                        val: localDateFromUTCDate(dueDate),
                      })
                    : t('models:tasks.create.dates.dueDate')}
                </Button>
              </Box>
            </Box>
            <AddMembersSection {...addMembersSectionProps} />
          </Box>
        </Box>
      </KeyboardAwareScrollView>
    </Box>
  );
};
