import {
  MaterialTopTabBarProps,
  createMaterialTopTabNavigator,
} from '@react-navigation/material-top-tabs';
import {
  CommonActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import { UploadProgressData } from 'expo-file-system';
import cloneDeep from 'lodash.clonedeep';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Dimensions,
  Modal,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Alert } from '@components/Alert';
import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import Popup from '@components/shared/Popup/Popup';
import { TabNavigatorSwipeSpacer } from '@components/shared/TabNavigatorSwipeSpacer';
import {
  Document,
  DocumentAttributes,
  DocumentSortOption,
  GetTaskDocument,
  LocalFile,
  Task,
  TaskMemberRole,
  useAddTaskFilesMutation,
  useGetTaskQuery,
} from '@graphql/generated';
import useDownloadFiles from '@hooks/useDownloadFiles';
import useFileProcessor from '@hooks/useFileProcessor';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { useUploadFiles } from '@hooks/useUploadFiles';
import { FilesStackScreenProps } from '@navigation/files/files-stack';
import { DownloadFiles } from '@screens/Files/DownloadFiles';
import { UploadFiles } from '@screens/Files/UploadFiles';
import { FilterType } from '@screens/Projects/ProjectFiles';
import AllTaskFiles from '@screens/Tasks/AllTaskFiles';
import TaskMedia from '@screens/Tasks/TaskMedia';
import theme from '@themes/theme';
import { useFilePicker } from '@utils/filePicker';

export interface TasksFilesProps {
  taskId: Task['id'];
  filterBy?: FilterType;
  sortBy?: DocumentSortOption;
  isSelectMode: boolean;
  onSelectedModeChange: (isSelected: boolean) => void;
  selectedList: Array<Document | LocalFile>;
  handelSelectedItem: (item: Document | LocalFile) => void;
}

const TaskFiles: React.FC = () => {
  const navigation = useNavigation();
  const Tab = createMaterialTopTabNavigator();
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<
    Array<Document | LocalFile>
  >([]);
  const [index, setIndex] = useState(0);

  const { setPreviewDocuments } = usePreviewDocuments();
  const [isProjectTaskFiles, setProjectTaskFiles] = useState<boolean>(false);
  const [forwardFileLoading, setForwardFileLoading] = useState<boolean>(false);
  const route = useRoute<FilesStackScreenProps<'task-files'>['route']>();
  const taskId = route?.params?.taskId;
  const { data: task } = useGetTaskQuery({
    variables: {
      id: taskId || '',
    },
  });
  const isViewer = task?.getTask.currentUserRole === TaskMemberRole.Viewer;
  const { t } = useTranslation('models');
  const { downloadFiles } = useDownloadFiles();
  const [showMsg, setShowMsg] = useState(false);

  const insets = useSafeAreaInsets();
  const [sortBy, setSortBy] = useState<DocumentSortOption>(
    DocumentSortOption.DateDesc
  );
  const [filterBy, setFilterBy] = useState<FilterType>(FilterType.NoFilter);
  const [isFilterOrSort, setIsFilterOrSort] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isMedia, setIsMedia] = useState<boolean>(true);
  const MAX_FILE_PHOTO_COUNT = 1000;
  const { launchImageSelection, launchFileSelection } = useFilePicker();
  const {
    uploadingFiles,
    setUploadingFiles,
    setUploadingErrorFiles,
    setUploadingSuccessFiles,
  } = useUploadFiles();

  const screenWidth = Dimensions.get('screen').width;

  const downloadImage = async (uri: string) => {
    const filename = uri.split('/').pop();
    const localUri = `${FileSystem.cacheDirectory}${filename}`;
    const { uri: localFileUri } = await FileSystem.downloadAsync(uri, localUri);
    return localFileUri;
  };
  const toggleSelectMode = () => {
    setIsSelectMode(false);
    setSelectedDocuments([]);
  };

  useEffect(() => {
    setIsSelectMode(false);
    setSelectedDocuments([]);
  }, [index]);
  useEffect(() => {
    const unsubscribe = navigation.addListener('state', (event) => {
      const index = event.data.state.routes[0].state?.index || 0;
      setIndex(index);
    });

    return unsubscribe;
  }, [navigation]);
  useEffect(() => {
    if (selectedDocuments && selectedDocuments.length == 0) {
      setIsSelectMode(false);
    }
    if (index != 0 || selectedDocuments.length <= 8) {
      setShowMsg(false);
    }
    setForwardFileLoading(false);
  }, [selectedDocuments]);
  const handelSelectedItem = (item: Document | LocalFile) => {
    const item0 = selectedDocuments?.find((item2) => {
      return item2.id == item.id;
    });
    if (item0) {
      const newArray = [...selectedDocuments].filter(
        (item3) => item3.id != item.id
      );
      setSelectedDocuments([...newArray]);
    } else {
      if (selectedDocuments.length < 8) {
        setSelectedDocuments([...selectedDocuments, item]);
      } else {
        Alert.alert('Maximum 8 files can be selected at a time.');
      }
    }
  };

  const filterTitleAndMenuList = () => {
    return (
      <Box
        style={[
          styles.popupBox,
          { top: insets.top + 32, right: theme.spacing.xl },
        ]}>
        <Popup
          width={200}
          title={t('projects.projectDetails.filesWithMedia.filter.title')}
          menuList={[
            {
              name: t(
                'projects.projectDetails.filesWithMedia.filter.myUploads'
              ),
              onSelect: () => {
                if (filterBy === FilterType.MyUploads) {
                  setFilterBy(FilterType.NoFilter);
                } else {
                  setFilterBy(FilterType.MyUploads);
                }
              },
              isSelected: filterBy === FilterType.MyUploads,
            },
          ]}
          closePopFn={() => setModalOpen(!modalOpen)}
        />
      </Box>
    );
  };
  const sortTitleAndMenuList = () => {
    return (
      <Box
        style={[
          styles.popupBox,
          { top: insets.top + 32, right: theme.spacing.m },
        ]}>
        <Popup
          width={200}
          title={t('projects.projectDetails.filesWithMedia.sort.title')}
          menuList={[
            {
              name: t(
                'projects.projectDetails.filesWithMedia.sort.dateUploaded'
              ),
              onSelect: () => setSortBy(DocumentSortOption.DateDesc),
              isSelected: sortBy === DocumentSortOption.DateDesc,
            },
            {
              name: t('projects.projectDetails.filesWithMedia.sort.name'),
              onSelect: () => setSortBy(DocumentSortOption.NameAsc),
              isSelected: sortBy === DocumentSortOption.NameAsc,
            },
          ]}
          closePopFn={() => setModalOpen(!modalOpen)}
        />
      </Box>
    );
  };

  const { processFiles } = useFileProcessor();
  const { me } = useMe();

  const [addTaskFiles] = useAddTaskFilesMutation({
    refetchQueries: [
      'listTaskDocuments',
      { query: GetTaskDocument, variables: { id: taskId } },
    ],
  });

  const [_uploadProgress, setUploadProgress] = useState({});
  const callback = (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => {
    const progress =
      uploadProgress.totalBytesSent / uploadProgress.totalBytesExpectedToSend;
    setUploadProgress({ ...uploadProgress, [documentClientId]: progress });
  };

  const getPhotos = (imageList: LocalFile[]) => {
    const documents = imageList.map((item, index): Document => {
      const {
        name,
        clientId,
        contentType,
        isImage = false,
        isAudio = false,
        duration = null,
      } = item;
      const documentId = `${taskId}-${index}`;

      return {
        __typename: 'Document',
        id: documentId,
        owner: me!,
        name: name || clientId,
        clientId,
        contentType: contentType,
        isImage,
        createdAt: new Date().toISOString(),
        size: item.size,
        file: {
          ...item,
          __typename: 'File',
          id: documentId,
          cdnBaseUrl: '',
          url: item.url,
        },
        isAudio,
        duration,
      };
    });

    setUploadingFiles([...uploadingFiles, ...documents]);

    processFiles(documents, callback).then(
      (response) => {
        taskId &&
          addTaskFiles({
            variables: {
              id: taskId,
              attributes: imageList.map((item): DocumentAttributes => {
                const {
                  name,
                  clientId,
                  contentType,
                  isImage = false,
                  isAudio = false,
                } = item;
                const { blobId } =
                  response.find(
                    (uploadResultItem) => uploadResultItem.clientId === clientId
                  ) || {};

                return {
                  name,
                  contentType,
                  isImage,
                  isAudio,
                  blobId,
                  clientId,
                } as DocumentAttributes;
              }),
            },
            onCompleted: () => {
              setUploadingSuccessFiles([...documents]);
              setUploadingFiles([]);
              {
                isMedia &&
                  navigation.dispatch(CommonActions.navigate('task-media'));
              }
            },
            onError: () => {
              setUploadingErrorFiles([...documents]);
              setUploadingFiles([]);
            },
          });
      },
      () => {
        setUploadingErrorFiles([...documents]);
        setUploadingFiles([]);
      }
    );
  };

  const addFilePart = (isMedia = false) => {
    const buttonName = isMedia
      ? t('projects.files.addMedia')
      : t('projects.files.addFile');
    return (
      <>
        <DownloadFiles />
        <UploadFiles />
        <Button
          prefix={<Icon variant='l' name='Plus' color='white' />}
          onPress={async () => {
            if (isMedia) {
              await launchImageSelection(MAX_FILE_PHOTO_COUNT).then(
                (r) => r && getPhotos(r)
              );
            } else {
              await launchFileSelection().then((r) => {
                if (!r) return;

                const documents = r.map((item) => {
                  return {
                    ...item,
                    path: '',
                    isAudio: false,
                    isImage: false,
                  };
                });
                getPhotos(documents);
              });
            }
          }}
          borderRadius='xl'
          variant='primary'
          prefixMarginRight='s'
          float='bottom-right'
          accessibilityLabel={buttonName}>
          {buttonName}
        </Button>
      </>
    );
  };
  const shouldEnableSelectMode = (isSelected: boolean, isViewer: boolean) =>
    isSelected && !isViewer;

  const renderTabBar = (props: MaterialTopTabBarProps) => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        paddingHorizontal='m'
        style={styles.topShadow}>
        <CustomTabBar {...props} spacing={theme.spacing.m} />
        <Icon
          name='Filter'
          variant='m'
          color='textPrimary'
          marginRight='m'
          onPress={() => {
            setIsFilterOrSort(true);
            setModalOpen(true);
          }}
        />
        <Icon
          name='BarChart2'
          variant='m'
          color='textPrimary'
          onPress={() => {
            setIsFilterOrSort(false);
            setModalOpen(true);
          }}
        />

        {isSelectMode && (
          <Box
            backgroundColor='white'
            paddingHorizontal='m'
            style={{
              ...StyleSheet.absoluteFillObject,
            }}>
            <Box
              flex={1}
              flexDirection='row'
              alignItems='center'
              backgroundColor='grey05'
              borderRadius='xs'
              height={50}
              paddingHorizontal='m'>
              <Text variant='labelEmphasized' color='white'>
                {t('shared:selectedWithCount', {
                  count: selectedDocuments.length,
                })}
              </Text>
              <Icon
                name='X'
                color='white'
                variant='l'
                marginLeft='xs'
                onPress={() => {
                  setSelectedDocuments([]);
                  setIsSelectMode(false);
                }}
              />

              <Box flex={1} />
              <Icon
                name='Download'
                color='white'
                variant='l'
                marginRight='m'
                onPress={() => {
                  downloadFiles(
                    selectedDocuments,
                    false,
                    props.state.index === 1
                  );
                  toggleSelectMode();
                }}
              />
              {forwardFileLoading && (
                <Box
                  width={40}
                  style={{ marginTop: -37, marginRight: 4, marginLeft: -4 }}>
                  {(Platform.OS === 'ios' || Platform.OS === 'web') && (
                    <ActivityIndicatorLoading
                      color={theme.colors.white}></ActivityIndicatorLoading>
                  )}
                  {Platform.OS === 'android' && (
                    <ActivityIndicator
                      color={theme.colors.white}
                      size={25}
                      style={[
                        {
                          position: 'absolute',
                          left: 0,
                          right: 0,
                          top: 7.5,
                        },
                      ]}
                    />
                  )}
                </Box>
              )}
              {!forwardFileLoading && (
                <Icon
                  name='Forward'
                  color='white'
                  variant='l'
                  paddingTop='xxs'
                  marginRight='m'
                  onPress={async () => {
                    if (
                      (index === 0 && selectedDocuments.length > 8) ||
                      (index !== 0 && selectedDocuments.length > 9)
                    ) {
                      setShowMsg(true);
                      setTimeout(() => {
                        setShowMsg(false);
                      }, 3000);
                    } else {
                      const newArray = new Array<Document>();

                      const selectedDocs = selectedDocuments.filter(
                        (f) =>
                          f.__typename === 'Document' &&
                          !f.file.url.startsWith('file:') &&
                          !f.file.url.startsWith('data:')
                      );
                      const selectedFile: [Document] = selectedDocuments.filter(
                        (f) =>
                          !(
                            f.__typename === 'Document' &&
                            !f.file.url.startsWith('file:') &&
                            !f.file.url.startsWith('data:')
                          )
                      );

                      setForwardFileLoading(true);

                      if (selectedDocs.length > 0) {
                        Promise.all(
                          selectedDocs.map((doc1) =>
                            downloadImage((doc1 as Document).file?.url)
                          )
                        )
                          .then((fileurls) => {
                            for (let x = 0; x < fileurls.length; x++) {
                              const xitem = selectedDocs[x] as Document;
                              xitem.file.url = fileurls[x];
                              newArray.push(cloneDeep(xitem));
                            }
                            setPreviewDocuments([...newArray, ...selectedFile]);
                            setForwardFileLoading(false);
                            navigation.navigate(
                              'project-forward-media-message'
                            );
                          })
                          .catch((_err) => {
                            setForwardFileLoading(false);
                            Alert.alert(
                              'Error happened when get file to forward to chat, Please try again.'
                            );
                          })
                          .finally(() => {
                            setForwardFileLoading(false);
                          });
                      } else {
                        setForwardFileLoading(false);
                        setPreviewDocuments([...selectedFile]);
                        navigation.navigate('project-forward-media-message');
                      }
                    }
                  }}
                />
              )}

              <Icon
                name='MoreVertical'
                color='white'
                variant='l'
                onPress={() => {
                  navigation.navigate('multiselect-file-more-modal', {
                    taskId,
                    isProjectTaskFiles,
                    selectedDocuments,
                    toggleSelectMode,
                  });
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <>
      <Modal
        animationType='fade'
        transparent={true}
        visible={modalOpen}
        onRequestClose={() => setModalOpen(false)}>
        <TouchableWithoutFeedback onPress={() => setModalOpen(!modalOpen)}>
          <Box style={styles.modal} />
        </TouchableWithoutFeedback>
        {isFilterOrSort ? filterTitleAndMenuList() : sortTitleAndMenuList()}
      </Modal>
      <Box flex={1} backgroundColor='white'>
        <Tab.Navigator
          screenOptions={{ swipeEnabled: Platform.OS !== 'web' }}
          sceneContainerStyle={{
            backgroundColor: 'transparent',
          }}
          tabBar={renderTabBar}
          initialRouteName='recent'
          backBehavior='none'>
          <Tab.Screen
            name='task-media'
            options={{
              tabBarLabel: 'Media',
              tabBarAccessibilityLabel: 'Media',
            }}
            initialParams={{ tabBarLabel: 'Media', taskId }}
            listeners={{
              tabPress: () => {
                !isMedia && setIsMedia(true);
              },
            }}
            children={() => (
              <Box flex={1}>
                <Box flex={1}>
                  <TaskMedia
                    taskId={taskId || ''}
                    filterBy={filterBy}
                    sortBy={sortBy}
                    isSelectMode={isSelectMode}
                    onSelectedModeChange={(isSelected: boolean) => {
                      setIsSelectMode(
                        shouldEnableSelectMode(isSelected, isViewer)
                      );
                      setProjectTaskFiles(true);
                    }}
                    selectedList={selectedDocuments}
                    handelSelectedItem={handelSelectedItem}
                  />
                </Box>
                {addFilePart(true)}
              </Box>
            )}
          />
          <Tab.Screen
            name='all-task-files'
            options={{
              tabBarLabel: 'All Files',
              tabBarAccessibilityLabel: 'All Files',
            }}
            initialParams={{ tabBarLabel: 'All Files', taskId }}
            listeners={{
              tabPress: () => {
                isMedia && setIsMedia(false);
              },
            }}
            children={() => (
              <Box flex={1}>
                <Box flex={1}>
                  <AllTaskFiles
                    taskId={taskId || ''}
                    filterBy={filterBy}
                    sortBy={sortBy}
                    isSelectMode={isSelectMode}
                    onSelectedModeChange={(isSelected: boolean) => {
                      setProjectTaskFiles(false);
                      setIsSelectMode(
                        shouldEnableSelectMode(isSelected, isViewer)
                      );
                    }}
                    selectedList={selectedDocuments}
                    handelSelectedItem={handelSelectedItem}
                  />
                </Box>
                {addFilePart()}
              </Box>
            )}
          />
        </Tab.Navigator>
        <TabNavigatorSwipeSpacer />
        {showMsg &&
          ((index === 0 && selectedDocuments.length > 8) ||
            (index !== 0 && selectedDocuments.length > 9)) && (
            <Box
              backgroundColor='alertRedMild'
              opacity={0.8}
              marginHorizontal='s'
              paddingHorizontal='s'
              paddingVertical='xs'
              borderRadius='xs'
              style={{
                position: 'absolute',
                bottom: 80,
                width: screenWidth - theme.spacing.s * 2,
              }}>
              <Box flex={1}>
                <Text color='alertRed' variant='labelSmall' lineHeight={16}>
                  {index === 0
                    ? t(
                        'projects.projectDetails.filesWithMedia.chatSendingImageLimit'
                      )
                    : t(
                        'projects.projectDetails.filesWithMedia.chatSendingFileLimit'
                      )}
                </Text>
              </Box>
            </Box>
          )}
      </Box>
    </>
  );
};

export default TaskFiles;

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    height: Dimensions.get('window').height,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  popupBox: {
    position: 'absolute',
    zIndex: 99,
  },
  topShadow: {
    backgroundColor: 'white',
    shadowColor: 'black',
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 25 },
    shadowRadius: 25,
    zIndex: 99,
  },
});
