import React from 'react';
import { Platform, TouchableOpacity } from 'react-native';

import { HyperLink } from '@components/HyperLink';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';

export type Props = {
  name: string;
  description?: string;
  completed: boolean;
  onPress: () => void;
  onTitlePress?: () => void;
  disabled: boolean;
};

const TaskNameAndDescription: React.FC<Props> = ({
  name,
  description,
  completed,
  onPress,
  onTitlePress,
  disabled,
}) => {
  return (
    <Box flex={1}>
      {Platform.OS !== 'web' && (
        <Box flexDirection='row' alignItems='center'>
          <RadioButton
            onPress={onPress}
            isSelected={completed}
            disabled={disabled}
          />
          <TouchableOpacity onPress={onTitlePress} disabled={!onTitlePress}>
            <Text
              marginLeft='xs'
              marginRight='m'
              variant='labelLarge'
              color={completed ? 'grey04' : 'textPrimary'}
              style={[
                {
                  flex: 1,
                  flexWrap: 'wrap',
                },
                completed && {
                  textDecorationLine: 'line-through',
                  textDecorationStyle: 'solid',
                },
              ]}>
              {name}
            </Text>
          </TouchableOpacity>
        </Box>
      )}

      {description && (
        <HyperLink>
          <Text
            accessibilityLabel='Description title'
            variant='bodySecondary'
            color='grey04'
            marginTop='m'>
            {description}
          </Text>
        </HyperLink>
      )}
    </Box>
  );
};

export default TaskNameAndDescription;
